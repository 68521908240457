function caisseVenteController($timeout, $translate, $scope, WS, $window, AppSettings, $localStorage) {
    'ngInject';
    const vm = this;
   
    vm.disable_save=false
    vm.filter_type = 'code_a_barre';
    vm.type_entete = 'bl';
    vm.bg_color = {
        'bl': '#262b4e',
        'avoir': '#e89510',
        'commande': '#277850',
        'facture': '#6b0a56',
        'devis': '#067f97',
        'cadeaux': '#c9484a',
        'inventaire': "#5a9325",
        'achat': "#dd3dd2",
    }
    vm.asci_calc = [
        8,53,219,111,103,104,105,106,100,101,102,109,97,98,99,107,96,110,13
    ];
    vm.asci_value = {
        8 : 'C',
        53 : '(',
        219 : ')',
        111 : '/',
        103 : '7',
        104 : '8',
        105 : '9',
        106 : '*',
        100 : '4',
        101 : '5',
        102 : '6',
        109 : '-',
        97 : '1',
        98 : '2',
        99 : '3',
        107 : '+',
        96 : '0',
        110 : '.',
        13 : 'Enter'
    };  

    vm.calc_afficheur_1 = '';
    vm.calc_afficheur_2 = '';

    vm.date = moment().format('DD/MM/YYYY')
    //depot
    if($localStorage.NOMADIS_CAISSE_DEPOT && $localStorage.NOMADIS_CAISSE_DEPOT.code){
        vm.depot = $localStorage.NOMADIS_CAISSE_DEPOT;
    }else{
        vm.depot = {}
    }
    //client
    if($localStorage.NOMADIS_CAISSE_CLIENT && $localStorage.NOMADIS_CAISSE_CLIENT.code){
        vm.client = $localStorage.NOMADIS_CAISSE_CLIENT;
    }else{
        vm.client = {}
    }
    //distributeur
    if($localStorage.DISTRIBUTEUR && $localStorage.DISTRIBUTEUR.code){
        vm.distributeur = $localStorage.DISTRIBUTEUR;
    }else{
        vm.distributeur = {}
    }
    //newBL
    if($localStorage.NOMADIS_CAISSE_ENTETE && $localStorage.NOMADIS_CAISSE_ENTETE.count_prods>0){
        vm.newbl = $localStorage.NOMADIS_CAISSE_ENTETE;
        vm.type_entete = vm.newbl.type ? vm.newbl.type : 'bl';

    }else{
        vm.type_entete = 'bl';
        vm.newbl = {};
        vm.newbl.lignes = {};
        vm.newbl.type='bl';
        vm.newbl.count_prods=0;
        vm.newbl.total_ht=0;
        vm.newbl.total_net_ht=0;
        vm.newbl.total_ttc=0;
        vm.newbl.total_net_ttc=0;
        vm.newbl.montant_total_tva=0;
        vm.newbl.montant_total_net_tva=0;
        vm.newbl.recu = 0
        vm.newbl.rendu = 0
        vm.newbl.recouvrement = false;
    }
    
    vm.count_produits=0;
    // vm.soussociete_code='1';
    // vm.depot_code='U06';
    // vm.client_code='90';
    vm.tarif='tarif';
    vm.per_page='15';

    vm.with_loader = false;
    vm.open_paiement=false;
    vm.autosearchprod=true //auto // manuelle
    vm.autoselectprod=true //auto // manuelle
    vm.afficheprodsplited=true //auto // manuelle

    vm.currentPage='PAGE_CAISSE';
    vm.currentCaisseLeft='PRODUCT';
    vm.currentCaisseAnimate = {
        'PRODUCT': 'animate__fadeInRight',
        'DEPOTS': 'animate__fadeOutLeft',
        'CLIENTS': 'animate__fadeOutLeft',
    }

    vm.show_espece=true;
    vm.show_cheque=true;
    vm.show_traite=true;
    vm.show_tickets=false;
    vm.show_commentaire=false;
    

    vm.list_bls = [];

    vm.changePageCaisse = (CURRENT_PAGE='PAGE_CAISSE', CURRENT_LEFT_SIDEBAR='PRODUCT') => {
        
        vm.currentPage= vm.currentPage==CURRENT_PAGE ? 'PAGE_CAISSE' : CURRENT_PAGE;
        vm.currentCaisseLeft= vm.currentCaisseLeft==CURRENT_LEFT_SIDEBAR ? 'PRODUCT' : CURRENT_LEFT_SIDEBAR;

        //animation : 
        vm.currentCaisseAnimate['PRODUCT'] = vm.currentCaisseLeft =='PRODUCT' ? 'animate__fadeInRight': 'animate__fadeOutLeft';
        vm.currentCaisseAnimate['DEPOTS'] = vm.currentCaisseLeft =='DEPOTS' ? 'animate__fadeInRight': 'animate__fadeOutLeft';
        vm.currentCaisseAnimate['CLIENTS'] = vm.currentCaisseLeft =='CLIENTS' ? 'animate__fadeInRight': 'animate__fadeOutLeft';
        
        //vm.open_paiement=false;

    }

    vm.toggleFullScreen = function () {
        var elem = document.documentElement;
        if (!document.fullscreenElement && !document.mozFullScreenElement &&
            !document.webkitFullscreenElement && !document.msFullscreenElement) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    };


   


    vm.toggleFullScreen();

    vm.clearFilterProd = () => {
        vm.gamme_code = '';
        vm.famille_code = '';
        vm.marque_code = '';
        vm.sousfamille_code = '';
        vm.filter_prod='';
    }
    vm.getGammes = function () {
        WS.get('gammes')
            .then(function (response) {
                vm.gammes = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getMarques = function () {
        WS.get('marques')
            .then(function (response) {
                vm.marques = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getFamilles = function () {
        WS.get('familles')
            .then(function (response) {
                vm.familles = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getSousfamilles = function () {
        WS.get('sousfamilles')
            .then(function (response) {
                vm.sousfamilles = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    //watch filter 
    $scope.$watch("vm.filter_prod", function(newValue, oldValue) {
        if (newValue==oldValue && vm.autosearchprod && vm.distributeur.code && vm.client.code && vm.depot.code){
            vm.selectProdsByFilter();
        }
    });

    vm.selectEntete = (prod=null) => {
        vm.filter_prod = '';

        if(prod){
            vm.produits = { [prod.code] : prod };
        }else{
            vm.produits = vm.newbl.lignes;
        }
        vm.count_produits = Object.keys(vm.produits).length;
        vm.changePageCaisse();

    }
    vm.selectProdsByFilter = function () { 

        vm.changePageCaisse();
        vm.searched_last_input = vm.filter_prod; 
        // console.log('vm.filter_prod',vm.filter_prod)
        if(vm.distributeur.code && vm.client.code && vm.depot.code){
            vm.loading=true;
            WS.getData('produits/selectProdsByFilter',{
                tarification : vm.tarif,
                soussociete_code: vm.soussociete_code,
                gamme_code: vm.gamme_code,
                famille_code: vm.famille_code,
                sousfamille_code: vm.sousfamille_code,
                marque_code: vm.marque_code,
                search: vm.filter_prod,
            })
                .then(function (response) {

                    vm.filter_prod = '';
                    vm.produits = response.data;
                    vm.count_produits = Object.keys(vm.produits).length;

                    //check if auto select 
                    if(vm.count_produits==1 && vm.autoselectprod){
                        //get code produit
                        const code = Object.keys(vm.produits)[0];
                        //vm.produits[code].quantite = 1;

                        //check if produit_code exist deja
                        if(vm.newbl.lignes[code]){
                            console.log('before : ', vm.newbl.lignes[code].quantite)
                            vm.newbl.lignes[code].quantite = Number(vm.newbl.lignes[code].quantite) + 1;
                            console.log('after : ', vm.newbl.lignes[code].quantite)


                        }else{
                            vm.newbl.lignes[code] = vm.produits[code];
                        }
                        vm.newbl.lignes[code].select_auto = true;
                        //console.log('vm.newbl.lignes', vm.newbl.lignes)

                        //mise ajour quantite : 
                        vm.produits[code].quantite=vm.newbl.lignes[code].quantite;
                    }
                    vm.loading=false;
                    $scope.$apply();
                })
                .then(null, function (error) {
                    vm.loading=false;
                    console.log(error);
                });
        }else{
            swal(
                $translate.instant('Attention!'),
                $translate.instant('Veuillez bien vérifier le Distributeur, Client et dépot avant d\'ajouter les produits'), 
                'warning'
            )
        }
        
    };
    //vm.selectProdsByFilter();


    /**
     * on change inputs on prods
     */

    vm.changeHtFromTTC = (produit) => {

        var ht = vm.produits[produit.code].prix_ttc / (1 + (vm.produits[produit.code].tva / 100))
        ht = parseFloat(ht).toFixed(3);
        ht = Number(ht);

        return ht;
    }

    vm.changeTtcFromHT = (produit) => {

        var ttc = Number(vm.produits[produit.code].prix_ht) + (Number(vm.produits[produit.code].prix_ht) * Number(vm.produits[produit.code].tva) / 100);
        ttc = ttc.toFixed(3);
        ttc = Number(ttc);

        return ttc;
    }

    vm.onChangeInputsProd = function(produit, input){

        console.log(produit, input)
        if(input=='prix_ttc'){
            vm.produits[produit.code].prix_ht = vm.changeHtFromTTC(produit);
            if(vm.newbl.lignes[produit.code]){
                vm.newbl.lignes[produit.code].prix_ttc = vm.produits[produit.code].prix_ttc
                vm.newbl.lignes[produit.code].prix_ht = vm.produits[produit.code].prix_ht
            }
        }

        if(input=='prix_ht'){
            vm.produits[produit.code].prix_ttc = vm.changeTtcFromHT(produit);
            if(vm.newbl.lignes[produit.code]){
                vm.newbl.lignes[produit.code].prix_ttc = vm.produits[produit.code].prix_ttc
                vm.newbl.lignes[produit.code].prix_ht = vm.produits[produit.code].prix_ht
            }
        }

        if(input=='quantite'){
            vm.produits[produit.code].carton = produit.quantite /produit.colisage;
            vm.produits[produit.code].carton = vm.produits[produit.code].carton.toFixed(2);
            vm.produits[produit.code].carton = Number(vm.produits[produit.code].carton);
            if(vm.newbl.lignes[produit.code]){
                vm.newbl.lignes[produit.code].quantite = vm.produits[produit.code].quantite
                vm.newbl.lignes[produit.code].carton = vm.produits[produit.code].carton
            }
        }

        if(input=='carton'){
            vm.produits[produit.code].quantite = produit.carton * produit.colisage;
            vm.produits[produit.code].quantite = vm.produits[produit.code].quantite.toFixed(0);
            vm.produits[produit.code].quantite = Number(vm.produits[produit.code].quantite);
            if(vm.newbl.lignes[produit.code]){
                vm.newbl.lignes[produit.code].quantite = vm.produits[produit.code].quantite
                vm.newbl.lignes[produit.code].carton = vm.produits[produit.code].carton
            }
        }

        if(input=='remise'){
            if(vm.newbl.lignes[produit.code]){
                vm.newbl.lignes[produit.code].remise = vm.produits[produit.code].remise
            }
        }
    }

    vm.selectDepots = function () {
        WS.get('depots/selectDepot').then(
            function (response) {
                vm.depots = response.data;

                //select default depot
                vm.depot = vm.depots[0];
                vm.newbl.depot_code=vm.depot.code;
                vm.newbl.commercial_code=vm.depot.user_code;

                if(vm.depot){
                    vm.getCreditAndPlafondByDepot(vm.depot.code);
                }
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };

    vm.selectSoussocietes = function () {
        WS.get('soussocietes/selectSoussocietes').then(
            function (response) {
                vm.soussocietes = response.data;
                if (vm.soussocietes.length == 1) {
                    vm.newbl.distributeur_code = vm.soussocietes[0].code;
                    vm.distributeur = vm.soussocietes[0]
                }else{
                    //select distribiteur by default
                    vm.newbl.distributeur_code = vm.soussocietes[0].code;
                    vm.distributeur = vm.soussocietes[0]
                }
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };


    vm.caisseDetailsADate = function () {
        WS.get('caisseVente/caisseDetailsADate').then(
            function (response) {
                vm.detailcaisse = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };

    vm.onSelectSousSociete = (distributeur) => {
        vm.newbl.distributeur_code=distributeur.code;
        vm.distributeur  = distributeur;

    }

    vm.onSelectDepot = (depot) => {
        vm.newbl.depot_code=depot.code;
        vm.newbl.commercial_code=depot.user_code;
        vm.depot = depot;
        vm.changePageCaisse();
    }

    vm.onSelectClient = (client) => {
        vm.newbl.client_code=client.code;
        vm.newbl.credit_client=client.solde;
        
        vm.client = client;
        vm.changePageCaisse();
    }


    vm.selectListClientByPaginate = (page = 1, per_page = 15, with_loader=false) => {
        var data = {
            page,
            per_page,
            searchbox: vm.searchbox
        };
        if(with_loader){
            vm.loading = true;
        }
        WS.getData('clients/selectListClientByPaginate', data)
            .then((response) => {
                vm.list_clients = response.data.data;
                vm.current_page = response.data.current_page;
                vm.previous = vm.current_page - 1;
                vm.next = vm.current_page + 1;
                vm.from = response.data.form;
                vm.last_page = response.data.last_page;
                vm.next_page_url = response.data.next_page_url;
                vm.per_page = response.data.per_page;
                vm.prev_page_url = response.data.prev_page_url;
                vm.to = response.data.to;
                vm.total = response.data.total;
                vm.paginate = [];
                for (var i = 1; i <= vm.last_page; i++) {
                    vm.paginate.push(i);
                }

                if(with_loader){
                    vm.loading = false;
                }
                //vm.client=vm.list_clients[0]
                //console.log(vm.list_clients);
                $scope.$apply();
            }, (error) => {

            })
    };
    vm.checkpaginate = function (current, page, lastpage) {
        if (page > current + 3 || page < current - 3) {
            return false;
        } else {
            return true;
        }
    }


    vm.getCreditAndPlafondByDepot = (depot_code) => {
        WS.getData('depots/getCreditAndPlafondByDepot', { depot_code }).then(
            (response) => {
                vm.plafond_depot = response.data;
                $scope.$apply();
            },
            (error) => {
                console.log(error);
            }
        );
    }


    vm.deleteProduct = (produit_code) => {

        if(vm.newbl.code){
            //delete prod from database by entetecommercial and produit_code
        }else{
            //delete prod from frentend only
            delete vm.newbl.lignes[produit_code];
        }
    }


    vm.addProduct = (product) => {
        vm.newbl.lignes[product.code] = product;
    }


    /**
     ** calculate total watch variable 
     */
        vm.newbl.count_prods=0;
        vm.newbl.total_ht=0;
        vm.newbl.total_net_ht=0;
        vm.newbl.total_ttc=0;
        vm.newbl.total_net_ttc=0;
        vm.newbl.montant_total_tva=0;
        vm.newbl.montant_total_net_tva=0;
    $scope.$watch("vm.newbl.lignes", function(newValues, oldValues) {
        // Calculate totalQuantite * prix_ttc and update 'total' property
        vm.newbl.total_ht=0;
        vm.newbl.count_prods=0;

        vm.newbl.total_net_ht=0;
        vm.newbl.total_ttc=0;
        vm.newbl.total_net_ttc=0;
        vm.newbl.montant_total_tva=0;
        vm.newbl.montant_total_net_tva=0;
        vm.newbl.count_prods= Object.keys(vm.newbl.lignes).length;
        //console.log('newValues', newValues)
        _.each(newValues, (element, code) => {

            

            //HT
            var total_ht = Number(element.quantite) * Number(element.prix_ht)
                total_ht= total_ht.toFixed(3)
                vm.newbl.total_ht += Number(total_ht)

            //TTC
            var total_ttc= Number(element.quantite) * Number(element.prix_ttc)
                total_ttc= total_ttc.toFixed(3)
                vm.newbl.total_ttc += Number(total_ttc)

            // tva 
            vm.newbl.montant_total_tva = vm.newbl.total_ttc - vm.newbl.total_ht;
            //NET HT
            var total_net_ht= (Number(element.quantite) * Number(element.prix_ht))
                total_net_ht= total_net_ht - (total_net_ht * Number(element.remise) / 100)
                total_net_ht= total_net_ht.toFixed(3)
                vm.newbl.total_net_ht+= Number(total_net_ht)

            //NET TTC
            var total_net_ttc= Number(element.quantite) * Number(element.prix_ttc)
                total_net_ttc= total_net_ttc - (total_net_ttc * Number(element.remise) / 100)
                total_net_ttc= total_net_ttc.toFixed(3)
                vm.newbl.total_net_ttc+= Number(total_net_ttc)

            // tva 
            vm.newbl.montant_total_net_tva = vm.newbl.total_net_ttc - vm.newbl.total_net_ht;
        })
    }, true);

    
    /**
     ** watch paiement and calcule 
     */
     $scope.$watchGroup([
            'vm.newbl.espece', 'vm.newbl.traite', 'vm.newbl.cheque', 'vm.newbl.ticket_cadeau_resultat', 'vm.newbl.total_net_ttc',
            'vm.newbl.recouvrement'
        ], function(newValues, oldValues) {

            
                vm.newbl.recu = 0
                vm.newbl.rendu = 0
                //console.log('newValues', newValues)
               
    
                if(vm.newbl.type=='bl' || vm.newbl.type=='commande'){
                    vm.newbl.recu += vm.newbl.espece ?  Number(vm.newbl.espece) : 0;
                    vm.newbl.recu += vm.newbl.traite ?  Number(vm.newbl.traite) : 0;
                    vm.newbl.recu += vm.newbl.cheque ?  Number(vm.newbl.cheque) : 0;
                    vm.newbl.recu += vm.newbl.ticket_cadeau_resultat ?  Number(vm.newbl.ticket_cadeau_resultat) : 0;
        
                    vm.newbl.rendu += vm.newbl.recu
                    vm.newbl.rendu -= vm.newbl.total_net_ttc ?  Number(vm.newbl.total_net_ttc) : 0;

                    if(vm.newbl.rendu<=0){
                        vm.newbl.recouvrement=false;
                    }
                    if(vm.newbl.recouvrement){
                        vm.newbl.rendu -= vm.client.solde;
                        if(vm.newbl.rendu<=0){
                            vm.newbl.rendu=0; 
                        }
                    }
                }
                if(vm.newbl.type=='avoir'){

                    vm.newbl.rendu += vm.newbl.espece ?  Number(vm.newbl.espece) : 0;
                    vm.newbl.rendu += vm.newbl.traite ?  Number(vm.newbl.traite) : 0;
                    vm.newbl.rendu += vm.newbl.cheque ?  Number(vm.newbl.cheque) : 0;
                    vm.newbl.rendu += vm.newbl.ticket_cadeau_resultat ?  Number(vm.newbl.ticket_cadeau_resultat) : 0;
        
                    //vm.newbl.rendu += vm.newbl.recu
                    //vm.newbl.rendu -= vm.newbl.total_net_ttc ?  Number(vm.newbl.total_net_ttc) : 0;

                    
                    if(vm.newbl.recouvrement){
                       // vm.newbl.rendu -= vm.client.solde;
                        if(vm.newbl.rendu<=0){
                            vm.newbl.rendu=0; 
                        }
                    }
                }

        }, true);


    /**
     ** Gestion des événements de la page
     ** 
     */
     
    $window.addEventListener('keyup',e => {
        if(vm.asci_calc.includes(e.keyCode)){
            $(`#asci-${e.keyCode}`).addClass('calc-btn-hover');
            // Remove CSS class after 1 second
            setTimeout(function() {
                $(`#asci-${e.keyCode}`).removeClass('calc-btn-hover');
            }, 300);
            
            var focusedInput = $("input:focus");
            var focusedId = focusedInput.attr("id");

            if(e.keyCode==13 && !focusedId){
                $('input#code_a_barre').focus();
            }
        }
    })


    function isValidEquation(input ='') {
        const equationPattern = /^[\d\+\-\*\/\(\) .]+$/; // Regex pattern for a valid mathematical equation
        return input==undefined || input=='' ? true: equationPattern.test(input);
    }

    vm.showOnCalculatrice = () => {

        var temp_value;
        vm.without_change=false;
        $('input:not(.out-calculator)').on('change', function(e){
            const value = $(this).val()
            console.log('value', value)
            //check if value is validate equation : 
            if(isValidEquation(value)){
                $(this).addClass('bg-input-success');
                $(this).removeClass('bg-input-error');
                $('.calc-afficheur-1').addClass('bg-input-success');
                $('.calc-afficheur-1').removeClass('bg-input-error');
            }else{
                $(this).removeClass('bg-input-success');
                $(this).addClass('bg-input-error');
                $('.calc-afficheur-1').removeClass('bg-input-success');
                $('.calc-afficheur-1').addClass('bg-input-error');
            }

            temp_value = value;

            if(!vm.without_change){
                vm.calc_afficheur_1 = value;
            }else{
                vm.calc_afficheur_1 = vm.last_equation ? vm.last_equation : value
                vm.calc_afficheur_2 = eval(vm.calc_afficheur_1)

            }

            $scope.$apply();

        }).on('keyup', function(e){
            //console.log('keyup')
            $(this).trigger('change');
            
        }).on('keydown', function(e){
            //check if tap enter
            if(e.keyCode==13){

                vm.last_equation = temp_value;
                console.log('typed enter...', temp_value)
                //check if value is equation valid
                if(isValidEquation(temp_value)){
                    vm.without_change=true;
                    vm.calc_afficheur_2 = eval(vm.calc_afficheur_1)
                    //change focused input to result
                    $(this).val(eval(temp_value))
                    $(this).addClass('bg-input-success');
                    $(this).removeClass('bg-input-error');
                    $('.calc-afficheur-1').addClass('bg-input-success');
                    $('.calc-afficheur-1').removeClass('bg-input-error');
                }else{
                   // vm.without_change=false;
                    $(this).removeClass('bg-input-success');
                    $(this).addClass('bg-input-error');
                    $('.calc-afficheur-1').removeClass('bg-input-success');
                    $('.calc-afficheur-1').addClass('bg-input-error');
                }

                $scope.$apply();

            }
        });;
    }

    vm.storeBl = (STATUS) => {
        console.log('storeBl', vm.newbl)
        vm.loading=true;
        vm.disable_save=true;
        WS.post('caisseVente/storeBl', vm.newbl)
        .then(function (response) {
            if (response.status == 200) {

                vm.list_bls.unshift({
                    code: response.data.code,
                    type: response.data.type,
                    total_net_ttc: response.data.total_net_ttc,
                }); 
                vm.loading=false;
                vm.disable_save=false;
                $scope.$apply();

                swal(
                    $translate.instant('Succé'),
                    $translate.instant(vm.type_entete+ ' ajoutée avec succées'),
                    'success'
                )
            }
        })
        .then(null, function (error) {
                console.log(error);
                vm.loading=false;
                vm.disable_save=false;
                $scope.$apply();
        });
    }

    /**
     * 
     * @param {*} STATUS SAVE OR EN_ATTENTE
     */
    vm.enregistrer = (STATUS='SAVE') => {
        vm.newbl.status=STATUS;
        vm.newbl.type=vm.type_entete
        
        switch (vm.type_entete) {
            case 'bl':
                vm.storeBl(STATUS)
                break;
        
            default:
                swal('Oups', 'Veuillez bien selectionner le type d\'entete', 'warning')
                break;
        }
    }

    

    /**
     ** focus by default
     */
    $(document).ready(() => {
        $('input#code_a_barre').focus();
    })

    /**
     ** capteur de paiement
     */
     $(document).ready(() => {
        $('.input-paiement').focus(function(e){
            console.log('paiement-focused')
            vm.open_paiement=true;
        });
    })

    $(document).ready(function() {
        var infoBl = $('.top-info-bl'); // Select the .info-bl div by its ID
        infoBl.scrollTop(infoBl.prop('scrollHeight'));
      });

    $scope.$watch('vm.open_paiement', (newValue, oldValue) => {

        $(document).ready(function() {
            var infoBl = $('.top-info-bl'); // Select the .info-bl div by its ID
            infoBl.scrollTop(infoBl.prop('scrollHeight'));
          });

          if(!newValue){
            $('.info-calculator').removeClass('animate__fadeInRight')
            $('.info-calculator').removeClass('animate__animated')
            $('.info-calculator').hide(0)
          }
          
    },true)



    
    /**
     ** on focus and select text
     */
    vm.selectText = ($event) => { 
        
        console.log('event', $event)
             $timeout(function() {
                $event.target.select();
            });
        vm.calc_afficheur_1 = '';
        vm.calc_afficheur_2 = '';
    }

    /**
     * watch entete
     */
    $scope.$watch('vm.newbl', (newValue, oldValue) => {
        $localStorage.NOMADIS_CAISSE_ENTETE = vm.newbl;  
    },true)

    $localStorage.NOMADIS_CAISSE_DEPOT;
    $scope.$watch('vm.client', (newValue, oldValue) => {
        $localStorage.NOMADIS_CAISSE_CLIENT = vm.client;
    },true)

    $scope.$watch('vm.depot', (newValue, oldValue) => {
        $localStorage.NOMADIS_CAISSE_DEPOT = vm.depot;  
    },true)

    $scope.$watch('vm.distributeur', (newValue, oldValue) => {
        $localStorage.NOMADIS_CAISSE_DISTRIBUTEUR = vm.distributeur;  
    },true)
}
export default {
    name: 'caisseVenteController',
    fn: caisseVenteController
};